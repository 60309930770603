<template lang="pug">
  .account_form
    form(@submit.prevent="onConfirm")
      table.register_inner
        tr
          th
            IconBase(width="21" height="15" icon-name="id-card")
              IconAdmin
            span 管理権限
          td.check_wrap
            input#is_admin(type="radio" name="isAdmin" v-model="formData.isAdmin" @change="isAdminChange" value="true" required)
            label(for="is_admin") あり
            input#is_general(type="radio" name="isAdmin" v-model="formData.isAdmin" @change="isAdminChange" value="false" required)
            label.last(for="is_general") なし
        tr
          th
            IconBase(width="18" height="15" icon-name="id-card")
              IconIdCard
            span 社員ID
          td.input_wrap
            input#employee_id(type="number" v-model.number="formData.id" @change="checkExistId" max="9999" min="0" placeholder="123" required)
        tr
          th
            IconBase(width="18" height="15" icon-name="id-card")
              IconUser
            span 氏名
          td.input_wrap
            input#name(type="input" v-model.trim="formData.name" @change="checkNameSpace" placeholder="山田 太郎" required)
            p.attention 姓名の間にスペースを入れてください。
        tr
          th
            IconBase(width="18" height="15" icon-name="id-card")
              IconUser
            span 氏名（ローマ字）
          td.input_wrap
            input#name_en(type="input" v-model.trim="formData.nameEn" @change="checkNameSpace" placeholder="Taro Yamada" required)
            p.attention 姓名の間にスペースを入れてください。
        tr
          th
            IconBase(width="18" height="15" icon-name="id-card")
              IconGender
            span 性別
          td.check_wrap
            input#male(type="radio" name="gender" v-model="formData.gender" value="male" required)
            label(for="male") 男性
            input#female(type="radio" name="gender" v-model="formData.gender" value="female" required)
            label.last(for="female") 女性
            p.attention 男女比のために使用するのみで、表示はされません。
        tr
          th
            IconBase(width="18" height="15" icon-name="id-card")
              IconEmail
            span メールアドレス
          td.input_wrap(v-if="this.$route.name === 'admin_register'")
            input#email(type="email" v-model.trim="formData.email" @change="checkExistEmail" placeholder="sei.mei@relation2012.com" required)
          td.input_wrap(v-else)
            input#email(type="email" :value="formData.email" readonly)
            p.attention.alert メールアドレスは変更できません。
        tr
          th
            IconBase(width="18" height="15" icon-name="id-card")
              IconPhone
            span 電話番号
          td.input_wrap
            input#tel(type="tel" v-model.trim="formData.tel" placeholder="09000000000（任意）")
            p.attention サブマネージャ以上はアカウントページに表示されます。
        tr
          th
            IconBase(width="18" height="15" icon-name="id-card")
              IconCalendarAlt
            span 入社日
          td.input_wrap
            Datepicker(
              id="hiredate_picker"
              class="input_wrap"
              v-model="formData.hireDate"
              :language="lang"
              :disabled-dates="disabledDates"
              format="yyyy/M/d"
            )
        tr
          th
            IconBase(width="18" height="15" icon-name="id-card")
              IconBuilding
            span 部署
          td.select_wrap
            select(v-model.number="formData.departmentId" required)
              option(v-for="(department, id) in master.departments" :key="id" :value="id") {{ department.name }}
        tr
          th
            IconBase(width="18" height="15" icon-name="id-card")
              IconPosition
            span 役職
          td.select_wrap
            select(v-model.number="formData.positionId" required)
              option(v-for="(position, id) in master.positions" :key="id" :value="id" :selected="position.name == '一般社員'") {{ position.name }}
      button.confirm_btn(type="submit") 入力確認
    ModalItem.confirm_modal(
      v-if="confirm"
      v-scroll-lock="confirm"
      @close="cancel"
      @submit="onRegister"
      submit="社員登録"
    )
      table.preview_wrap
        tbody
          tr
            th 管理権限
            td {{ this.formData.isAdmin === true || this.formData.isAdmin == 'true' ? 'あり' : 'なし' }}
          tr
            th 社員ID
            td {{ this.formData.id|employeeId }}
          tr
            th 名前
            td {{ this.formData.name }}（{{ this.formData.nameEn }}）
          tr
            th 性別
            td {{ this.formData.gender == 'male' ? '男性' : '女性' }}
          tr
            th Email
            td {{ this.formData.email }}
          tr
            th 電話番号
            td {{ this.formData.tel == '' ? '-' : this.formData.tel }}
          tr
            th 入社日
            td {{ formatDate(this.formData.hireDate) }}
          tr
            th 所属部署
            td {{ this.formData.departmentId|convertMasterName('departments', master) }}
          tr
            th 役職
            td {{ this.formData.positionId|convertMasterName('positions', master) }}
</template>

<script>
import { mapState } from 'vuex'
import IconBase from '@/components/IconBase.vue'
import IconIdCard from '@/components/icons/IconIdCard.vue'
import IconAdmin from '@/components/icons/IconAdmin.vue'
import IconEmail from '@/components/icons/IconEmail.vue'
import IconPhone from '@/components/icons/IconPhone.vue'
import IconCalendarAlt from '@/components/icons/IconCalendarAlt.vue'
import IconUser from '@/components/icons/IconUser.vue'
import IconGender from '@/components/icons/IconGender.vue'
import IconPosition from '@/components/icons/IconPosition.vue'
import IconBuilding from '@/components/icons/IconBuilding.vue'
import ModalItem from '@/components/ModalItem.vue'
import Datepicker from 'vuejs-datepicker'
import { ja } from 'vuejs-datepicker/dist/locale'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('ja')
export default {
  name: 'AdminRegisterView',
  components: {
    IconBase,
    Datepicker,
    IconAdmin,
    IconIdCard,
    IconEmail,
    IconPhone,
    IconCalendarAlt,
    IconUser,
    IconGender,
    IconPosition,
    IconBuilding,
    ModalItem,
  },
  props: {
    formData: {
      type: Object,
      required: false,
      default: () => {
        return {
          isAdmin: false,
          id: null,
          name: '',
          nameEn: '',
          gender: 'male',
          email: '',
          tel: '',
          hireDate: new Date(),
          departmentId: 5,
          positionId: 11, // 一般社員
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      lang: ja,
      disabledDates: {
        to: new Date('2000/1/1'),
      },
      confirm: false,
    }
  },
  computed: {
    ...mapState('masterModule', ['master']),
    ...mapState('usersModule', ['users']),
  },
  watch: {
    'formData.id': function (val) {
      this.formData.id = val > 9999 ? 9999 : val < 1 ? '' : val
    },
    'formData.name': function (val) {
      this.formData.name = this.trimZenSpace(val)
    },
    'formData.nameEn': function (val) {
      this.formData.nameEn = this.trimZenSpace(val)
    },
    'formData.email': function (val) {
      this.formData.email = this.trimAllSpace(val)
    },
    'formData.tel': function (val) {
      if (val) {
        let tel = this.trimAllSpace(val.replace('-', ''))
        this.formData.tel = tel.length > 12 ? tel.slice(0, -1) : tel
      }
    },
  },
  methods: {
    isAdminChange(event) {
      this.formData.isAdmin = event.target.value == 'true'
    },
    onConfirm() {
      if (
        this.formData.name.indexOf(' ') < 0 ||
        this.formData.nameEn.indexOf(' ') < 0
      ) {
        alert('姓名の間にスペースを入力してください。')
      } else if (this.formData.id == '' || this.formData.id == 0) {
        return false
      } else {
        this.confirm = true
      }
    },
    cancel() {
      this.confirm = false
    },
    async onRegister() {
      this.formData.isAdmin =
        this.formData.isAdmin === true || this.formData.isAdmin == 'true'
      this.$emit('submit', { formData: this.formData })
    },
    checkExistId(event) {
      const id = event.target.value
      if (this.$route.params['id'] != id && id in this.users) {
        this.formData.id = ''
        const msg = this.users[id].name + 'さんの社員IDと重複します。'
        alert(msg)
      }
    },
    checkNameSpace(event) {
      const name = event.target.value
      if (name.indexOf(' ') < 0) {
        alert('姓名の間にスペースを入力してください。')
      }
    },
    checkExistEmail(event) {
      const email = event.target.value
      Object.keys(this.users).some((id) => {
        const account = this.users[id]
        if (email == account.email) {
          this.formData.email = ''
          const msg = account.name + 'さんのメールアドレスと重複します。'
          alert(msg)
          return true
        }
      })
    },
    trimZenSpace(val) {
      return val.replace(/[\x20\u3000]/, ' ')
    },
    trimAllSpace(val) {
      return val.replace(/\s+/g, '')
    },
    formatDate(date) {
      return dayjs(new Date(date)).format('YYYY/MM/DD')
    },
  },
}
</script>

<style lang="scss" scoped>
.register_inner {
  display: block;
  max-width: 1020px;
  margin: 40px auto 0;
  padding: 0 20px 20px;
  text-align: left;
  border-collapse: collapse;
  @include tab {
    display: table;
  }
  @include pc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  tr {
    display: block;
    @include tab {
      display: table-row;
    }
    @include pc {
      width: calc(50% - 20px);
      margin: 0 0 20px;
    }
    th {
      display: flex;
      align-items: center;
      font-size: 15px;
      margin: 0 0 10px;
      color: #6ba392;
      @include pc {
        width: 100%;
      }
      svg {
        margin: 0;
      }
      span {
        margin: 0 0 0 5px;
        color: #555;
      }
    }
    td {
      display: block;
      padding: 10px 5px;
      margin: 0 auto 15px;
      @include pc {
        width: 100%;
      }
      .attention {
        width: 100%;
        margin: 5px 0 0;
        padding: 0 5px;
        color: rgb(153, 153, 153);
        font-size: 12px;
        &::before {
          content: '※';
        }
        &.alert {
          color: #ee7272;
        }
      }
      &.input_wrap {
        input {
          line-height: 1.2;
          font-size: 13px;
          letter-spacing: 2px;
          padding: 10px 15px;
          display: block;
          width: calc(100% - 30px);
          border-radius: 10px;
          border: 1px solid #d1d1d1;
          background: #fff;
          resize: none;
        }
      }
      &.check_wrap {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        input {
          display: none;
        }
        label {
          display: block;
          width: 30%;
          max-width: 200px;
          padding: 12px 10px;
          border-radius: 20px;
          border: 1px solid #d1d1d1;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
        }
        input:checked + label {
          border: 1px solid #0ab38e;
          box-shadow: 0 0 0 1px #0ab38e inset;
          color: #0ab38e;
        }
        .attention {
          text-align: center;
          padding: 0;
        }
      }
      &.select_wrap {
        position: relative;
        select {
          padding: 10px 15px;
          display: block;
          min-width: 100%;
          border-radius: 30px;
          border: 1px solid #d1d1d1;
          cursor: pointer;
          &::-ms-expand {
            display: none;
          }
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 45%;
          right: 20px;
          content: '';
          width: 8px;
          height: 8px;
          border-top: 2px solid #444;
          border-right: 2px solid #444;
          transform: translateY(-50%) rotate(135deg);
          @include tab {
            right: 30px;
          }
        }
      }
    }
    @include tab {
      th,
      td {
        display: table-cell;
        padding: 20px 15px;
        margin: 0;
        align-items: center;
        &.check_wrap {
          justify-content: flex-start;
          label {
            max-width: 150px;
            &.last {
              margin: 0 0 0 20px;
            }
          }
          .attention {
            text-align: left;
          }
        }
      }
    }
    @include pc {
      th,
      td {
        display: block;
        padding: 10px 15px;
        width: calc(100% - 30px);
        &.check_wrap {
          justify-content: center;
          .attention {
            text-align: center;
          }
        }
      }
      th {
        display: flex;
        align-items: flex-start;
      }
    }
  }
}
.confirm_btn {
  display: block;
  margin: 0 auto 40px;
  min-width: 300px;
  max-width: 350px;
  width: 80%;
  text-align: center;
  border-radius: 10px;
  padding: 15px;
  background-color: #88cabe;
  background-image: linear-gradient(315deg, #6bc5b3 0%, #88cabe 74%);
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  @include tab {
    margin: 30px auto;
  }
}
.confirm_modal {
  .preview_wrap {
    margin: 10px auto;
    text-align: left;
    width: 100%;
    tbody tr {
      th {
        font-size: 14px;
        color: #116956;
      }
      th,
      td {
        font-size: 12px;
        padding: 15px 8px 12px;
        border-bottom: 1px solid #d1d1d1;
      }
      &:last-child {
        th,
        td {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#hiredate_picker {
  font-size: 14px;
  letter-spacing: 2px;
  padding: 10px 15px;
  display: block;
  min-width: 100%;
  border-radius: 30px;
  border: 1px solid #d1d1d1;
  cursor: pointer;
}
.vdp-datepicker .vdp-datepicker__calendar {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
